<template>
  <v-form>
    <v-row>
      <v-col cols="6">
        <v-text-field
          outlined
          dense
          label="Sales Employee Name"
          v-model="record.SlpName"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          outlined
          dense
          label="Sales Employee Code"
          v-model="record.SlpCode"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-autocomplete
          outlined
          dense
          v-model="record.ChannCode"
          :items="channels"
          item-text="name"
          item-value="id"
          label="Channel"
        ></v-autocomplete>
      </v-col>

      <v-col cols="6">
        <v-autocomplete
          outlined
          dense
          v-model="record.TierCode"
          :items="tiers"
          item-text="name"
          item-value="id"
          label="Tier"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12">
        <v-btn color="accent" @click="sendData" :loading="loader">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    record: {},
    loader: false,
    channels: [],
    tiers: [],
    date: new Date().toISOString().substr(0, 10),
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      console.log(val, "init");
      this.record = { ...val };
    },
    setDate(date) {
      this.record.date = date;
    },
    sendData() {
      this.loader = true;
      this.$emit("data", this.record);
    },
    getChannels() {
      const self = this;
      this.$store
        .dispatch("get", `/channels`)
        .then((res) => {
          self.channels = res;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getTiers() {
      const self = this;
      this.$store
        .dispatch("get", `/tiers`)
        .then((res) => {
          self.tiers = res;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getChannels();
    this.getTiers();
  },
};
</script>