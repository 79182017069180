<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>New Sales Employee</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" small text to="/sales-employees">
          <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- editor -->
        <editor :initial="dataItem" @data="save"></editor>
        <!-- end -->
      </v-card-text>
    </v-card>
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: {},
  }),
  methods: {
    save(data) {
      data.job_title = "Sales Agent";
      const url = "/employee";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          self.$router.push("/sales-employees");
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
  },
};
</script>